<template>
  <contacts-add-edit v-model="contact" title="Edit Location Contact" :entity-name="location.name" :edit="true" @onSave="save">
    <template #description>
      <p>An email will be sent to this contact to create an account used to login to the Client Portal. Contacts added to this location will only have access to this location.</p>
    </template>
  </contacts-add-edit>
</template>
<script>
import ContactsAddEdit from "@/modules/shared/contacts/ContactsAddEdit";
import { GET_CLIENT_CONTACT_DETAILS } from "@/modules/shared/contacts/graph/queries";
import { GET_LOCATION_NAME } from "@/modules/admin/locations/graph/query";
import { SAVE_USER, UPDATE_CLIENT_CONTACT, UPDATE_CLIENT_CONTACT_NO_USER } from "@/modules/shared/contacts/graph/mutations";

export default {
  components: { ContactsAddEdit },
  data: () => ({
    location: {
      name: "",
    },
    contact: {
      user: {},
      address: {
        country: "",
      },
    },
  }),
  methods: {
    save() {
      const phone = this.contact.phone.replace(/\D/g, "");
      if (!this.contact.user.id && this.contact.user.role) {
        this.$apollo
          .mutate({ mutation: SAVE_USER, variables: { user: { username: this.contact.email.toLowerCase(), password: "INVALID", role: this.contact.user.role } } })
          .then(({ data }) => {
            const userID = data.user.id;
            return this.$apollo.mutate({
              mutation: UPDATE_CLIENT_CONTACT,
              variables: {
                contactID: this.contact.id,
                firstName: this.contact.firstName,
                lastName: this.contact.lastName,
                phone,
                email: this.contact.email.toLowerCase(),
                addressID: this.contact.address.id,
                address1: this.contact.address.address1,
                address2: this.contact.address.address2,
                city: this.contact.address.city,
                postalCode: this.contact.address.postalCode,
                state: this.contact.address.state,
                country: this.contact.address.country,
                userID,
                role: this.contact.user.role,
              },
            });
          })
          .finally(() => this.$router.back());
      } else if (this.contact.user.id) {
        this.$apollo
          .mutate({
            mutation: UPDATE_CLIENT_CONTACT,
            variables: {
              contactID: this.contact.id,
              firstName: this.contact.firstName,
              lastName: this.contact.lastName,
              phone,
              email: this.contact.email.toLowerCase(),
              addressID: this.contact.address.id,
              address1: this.contact.address.address1,
              address2: this.contact.address.address2,
              city: this.contact.address.city,
              postalCode: this.contact.address.postalCode,
              state: this.contact.address.state,
              country: this.contact.address.country,
              userID: this.contact.user.id,
              role: this.contact.user.role,
            },
          })
          .finally(() => this.$router.back());
      } else {
        this.$apollo
          .mutate({
            mutation: UPDATE_CLIENT_CONTACT_NO_USER,
            variables: {
              contactID: this.contact.id,
              firstName: this.contact.firstName,
              lastName: this.contact.lastName,
              phone,
              email: this.contact.email.toLowerCase(),
              addressID: this.contact.address.id,
              address1: this.contact.address.address1,
              address2: this.contact.address.address2,
              city: this.contact.address.city,
              postalCode: this.contact.address.postalCode,
              state: this.contact.address.state,
              country: this.contact.address.country,
            },
          })
          .finally(() => this.$router.back());
      }
    },
  },
  apollo: {
    contact: {
      fetchPolicy: "no-cache",
      query: GET_CLIENT_CONTACT_DETAILS,
      variables() {
        return {
          id: this.$route.params.contactId,
        };
      },
      update: (data) => data.contact,
      result({ data }) {
        if (!data.contact.user) {
          this.contact.user = {};
        }
      },
    },
    location: {
      query: GET_LOCATION_NAME,
      variables() {
        return {
          id: this.$route.params.locationID,
        };
      },
      update: (data) => data.location,
    },
  },
};
</script>
